import trs from './trs.module.scss';
import { useState, useEffect } from 'react';
import { csAmount } from '../../pages/dashboard/util';

function TrsUnit({ t, v }) {
  return (
    <div className={trs.col}>
      <div className={trs.h}>{t}</div>
      <div className={trs.s}>{v}</div>
    </div>
  );
}

export default function TrsRow({ amount, type, date, status, currency = `$` }) {
  const [h, sH] = useState('');

  function v(c) {
    return c?.toString()?.padStart(2, '0');
  }

  useEffect(() => {
    try {
      let y = new Date(date);
      let w = `${v(y?.getDate())}/${v(y?.getMonth())}/${y.getFullYear()}`;
      sH(w);
    } catch (e) {
      console.log(h, e);
    }
  }, []);

  return (
    <div className={trs.trsr}>
      <div className={trs.ic}></div>

      <TrsUnit t="Amount" v={`${currency}${amount ? csAmount(amount) : '0.00'}`} />
      <TrsUnit t="Type" v={type} />
      <TrsUnit t="Date" v={h} />

      <div className={`${trs.but} ${trs?.[status]}`}> {status} </div>
    </div>
  );
}

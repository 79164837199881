import s from './sty.module.scss';
import i from './icons.module.scss';
import { useRef, useEffect } from 'react';

export function logout() {
  window.localStorage.removeItem('baUid');
  window.localStorage.removeItem('account_verification');
  window.localStorage.setItem('loggedOut', true);

  window.localStorage.removeItem('email');
  window.localStorage.removeItem('notverified');
  window.localStorage.removeItem('close');
  //window.localStorage.setItem('close', true);

  //window.location.href = '/wp-user/dashboard/logout';
}
function Ro({ icon, v, href = '', onClick }) {
  return (
    <a className={s.ro} href={href} onClick={onClick}>
      <div className={i[icon]}></div>
      <div className={s.words}>{v}</div>
    </a>
  );
}
export default function DropDown({ label, value = null }) {
  return (
    <>
      <div className={s.cw}>
        <div className={s.c}>
          <div className={s.ubox}>
            <div className={s.img}>{value?.picture ? <img src={`/wp-user/uploads/${value?.picture}`} /> : null}</div>
            <div className={s.r}>
              <div className={s.n}>{value?.fullname}</div>
              <div className={s.t}>{value?.current_plan}</div>
            </div>
          </div>

          <div className={s.li}>
            <Ro icon="home" v="Home" href="/wp-user/dashboard" />
            <Ro icon="profile" v="Profile" href="/wp-user/dashboard/profile" />
            <Ro icon="settings" v="Settings" href="/wp-user/dashboard/settings" />
            <Ro
              icon="logout"
              v="Logout"
              onClick={() => {
                logout();
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

import s from './style.module.scss';
import Input2 from '../../../components/input2';
import Input1 from '../../../components/input';
import { useRef, useState, useEffect } from 'react';
import SubmitBtn from '../../../components/submit';
import { loaderPostFetch } from '../util';
import SendNotice from '../../../components/sendnotice';

import { useActionData, useSubmit, useLocation } from 'react-router-dom';

import Card, { Card2 } from '../../../components/card';
import TextAr from '../../../components/textarea';

import Notif from '../../../components/notif';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

export async function caction({ request, params }) {
  const formData = await request.formData();
  let nformData = new FormData();
  let extras = JSON.parse(formData.get('extra'));
  console.log({ extras });
  Object.keys(extras).forEach((j) => {
    nformData.append(j, extras[j]);
  });

  const updates = Object.fromEntries(formData);
  //update old
  Object.keys(updates).forEach((j) => {
    nformData.append(j, updates[j]);
  });

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    nformData.append('token', tok);
  }
  const contact = await loaderPostFetch(`api/users.php`, nformData);

  return contact;
}

export async function cloader({ params }) {
  const formData = new FormData();
  formData.append('gettrslist', 'gettrslist');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const perc2 = await loaderPostFetch(`api/users.php`, formData);

  const formData2 = new FormData();
  formData2.append('getperc', 'getperc');
  if (tok != null) {
    formData2.append('token', tok);
  }
  const perc = await loaderPostFetch(`api/users.php`, formData2);

  /*
  const formData = new FormData();
  formData.append('getperc', 'getperc');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const perc = await loaderPostFetch(`api/users.php`, formData);
*/
  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, perc, ...perc2 };
}

export default function Confirm() {
  const { state } = useLocation();
  const { perc, trx_notif } = useLoaderData();

  let actionData = useActionData();

  const [n, sN] = useState({ display: false, type: 'success', msg: '' });
  const [no, sNo] = useState(false);

  useEffect(() => {
    console.log({ state, perc });
  }, [state]);
  //tb

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.icon) {
        if (s?.icon == 'success') {
          sN({ ...n, type: 'success', msg: 'Successful! Await Confirmation' });
          window.location.href = `../`;
        } else {
          sNo(s?.trx_notif);
          //sN({ ...n, type: 'error', msg: 'Something went wrong!' });
        }
      }
      /*sN({ display: true, type: 'success', msg: `${s?.message1}` });
       */
      setTimeout(() => {
        sN({ display: false, type: 'success', msg: `` });
      }, 5000);
    }
  }, [actionData]);

  const [newAmount, sNewAmount] = useState(Number(state?.amount));

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      {no ? <SendNotice display={true} t={no?.[0]?.title} b={no?.[0]?.body} /> : null}

      <div>
        <div className={s.h} style={{ marginBottom: '1rem' }}>
          Confirm Transaction
          {/*JSON.stringify({...state})*/}
        </div>

        <Form method="post">
          <input type="hidden" name="newtrs" value="newtrs" />

          <input type="hidden" name="extra" value={JSON.stringify({ ...state })} />

          <Card2>
            <Input2 label="Bank*" value={state?.bank} placeholder="" name="bank" />

            <Input2 label="Account Number *" value={state?.act_no} placeholder="" name="act_no" />

            <Input2 label="Account Holder Name *" value={state?.act_hold_name} placeholder="" name="act_hold_name" />

            <Input2
              label="Amount *"
              value={newAmount}
              placeholder=""
              onChange={(e) => {
                console.log(e.target.value);
                sNewAmount(Number(e.target.value));
              }}
              name="amount"
            />
            {newAmount > (perc?.tb ? perc?.tb?.current_balance : 0) ? <p>Insufficient balance</p> : null}

            <Input2
              label="Service Charge *"
              value={((perc?.percent ? perc?.percent?.percent : 10) / 100) * newAmount}
              placeholder=""
            />

            <Input2
              label="Amount to be received *"
              value={newAmount - ((perc?.percent ? perc?.percent?.percent : 10) / 100) * newAmount}
              placeholder=""
            />

            <div
              onClick={() => {
                if (newAmount > (perc?.tb ? perc?.tb?.current_balance : 0)) {
                  //alert("h");
                  sN({ display: true, type: 'success', msg: `Insufficient balance` });

                  setTimeout(() => {
                    sN({ display: false, type: 'success', msg: `` });
                  }, 5000);
                } else {
                  console.log('h');
                }
              }}
            >
              <SubmitBtn label="Confirmed" disabled={newAmount > (perc?.tb ? perc?.tb?.current_balance : 900000)} />
            </div>
          </Card2>
        </Form>
      </div>
    </>
  );
}

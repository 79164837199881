import Input2, { Input3, CountryOptions } from '../../../components/input2';
import SubmitBtn2 from '../../../components/submit2';
import sty from './s.module.scss';
import Header2 from '../../../components/header2';
import Header3 from '../../../components/header3';
import Footer2 from '../../../components/footer2';
//import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import Notif from '../../../components/notif';
import Select2 from '../../../components/select2';
import { loaderPostFetch } from '../util';
import { useEffect, useState } from 'react';
import UploadFile, { UploadFile2 } from '../../../components/uploadfile';
import { logout } from '../../../components/dropdown';

import { Outlet, Link, useLoaderData, useActionData, Form } from 'react-router-dom';

export async function uraction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();
  formData.append('submit_verification', 'submit_verification');

  var blob = new Blob([JSON.stringify([0, 1, 2])], { type: 'image/jpeg' });
  var fileOfBlob = new File([blob], 'empty.jpeg');

  formData.append('idcardFront', fileOfBlob, 'empty.jpeg');
  formData.append('pic', fileOfBlob, 'empty.jpeg');

  let tok = window.localStorage.getItem('tempbaUid');
  if (tok != null) {
    formData.append('token', tok);
  }

  /*const updates = Object.fromEntries(formData);
  updates['action'] = 'code';
  let contact;
  //const contact = await
  loaderPostFetch(`api/users.php`, updates)
    .then((s) => {
      if (s?.error) {
        //s?.message
      } else {
      }
    })
    .catch((e) => {
      console.log(e);
    });

  return { contact };*/

  const contact = await loaderPostFetch(`api/users.php`, formData);

  return contact;
}
export async function urloader({ params }) {
  /*const formData = new FormData();
  formData.append('getdocs', 'getdocs');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);*/

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params };
  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  //return { ...params };
}

export default function UnderReview() {
  const { email, docs } = useLoaderData();

  let actionData = useActionData();
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    let r = window.localStorage.getItem('close');
    if (r) {
      window.localStorage.removeItem('close');
      window.location.href = '/wp-user/auth/';
    } else {
      window.localStorage.setItem('close', true);
    }
  }, []);

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.icon == 'success') {
        /*window.localStorage.setItem('baUid', s?.jwt);
        window.localStorage.removeItem('tempbaUid');

        window.location.href = '../dashboard';*/
      }
      /*if (s?.error) {
        //s?.message

        sN({ display: true, type: 'error', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      } else {
        window.localStorage.setItem('baUid', s?.jwt);
        //window.location.reload();
        sN({ display: true, type: 'success', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'success', msg: `` });
          window.location.href = '../../dashboard';
        }, 5000);
      }*/
    }
  }, [actionData]);

  return (
    <div className={sty.bg}>
      <Header3
        title="Logout"
        onClick={() => {
          //window.location.href = `https://${window.location.hostname}/wp-user/auth/`;
          logout();
          window.location.href = `https://${window.location.hostname}/wp-user/auth/`;
        }}
      />

      <div className={sty.reviewbox}>
        <div className={sty.sub}>
          <div className={sty.text}>Kyc Under Review</div>

          <div className={sty.lock2}></div>
        </div>

        <div className={sty.s}>
          We are reviewing your documents please be patient our team of experts are available 24/7 to analyze your
          provided information.
        </div>

        <div className={sty.s}>you will receive a notification via email on the update of your Verification.</div>

        <Form>
          <SubmitBtn2
            label="Okay"
            onClick={() => {
              logout();
              window.location.href = `https://${window.location.hostname}/wp-user/auth/`;
            }}
          />
        </Form>
      </div>

      {/*<div className={sty.resend}>
        Haven’t gotten the mail yet? <a>Resend</a>
      </div>*/}

      <Footer2 />
    </div>
  );
}

import sty from './h.module.scss';

export default function Header3({ href, title, onClick }) {
  return (
    <div className={sty.he}>
      <img
        src="/wp-user/b/logo2.png"
        onClick={() => {
          window.location.href = '/wp-user/auth/';
        }}
      />
      <a href={href}>
        <span onClick={onClick}>{title}</span>
      </a>
    </div>
  );
}

import Input2 from '../../../components/input2';
import SubmitBtn2 from '../../../components/submit2';
import sty from './s.module.scss';

import Footer2 from '../../../components/footer2';
import Header2 from '../../../components/header2';
import { Outlet, Link, useLoaderData, useActionData, Form } from 'react-router-dom';

import Notif from '../../../components/notif';
import { loaderPostFetch } from '../util';
import { useEffect, useState } from 'react';

export async function signupaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();

  const email = formData.get('email');
  const password = formData.get('password');
  const errors = {};

  // validate the fields
  if (typeof email !== 'string' || !email.includes('@')) {
    errors.error = true;
    errors.email = "That doesn't look like an email address";
  }

  if (typeof password !== 'string' || password.length < 6) {
    errors.error = true;
    errors.password = 'Password must be > 6 characters';
  }

  // return data if we have errors
  if (Object.keys(errors).length) {
    return errors;
  }

  const updates = Object.fromEntries(formData);
  formData.append('fullname', `${updates?.first_name} ${updates.last_name}`);

  const contact = await loaderPostFetch(`api/users.php?crud=newuser`, formData);

  return { ...contact, data: updates };
  //return { contact };
}

export default function Signup() {
  let actionData = useActionData();
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.error) {
        //s?.message

        sN({ display: true, type: 'error', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      } else {
        //window.localStorage.setItem('baUid', s?.jwt);
        //window.location.reload();
        sN({ display: true, type: 'success', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'success', msg: `` });
          //window.location.href = '../../dashboard';
          window.location.href = `/wp-user/auth/verify/${s?.data?.email}`;
        }, 5000);
      }
    }
  }, [actionData]);

  //requires params
  return (
    <div>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <Header2 href="login" title="Sign In" />

      <div className={sty.sub}>
        <div className={sty.text}>Set up your profile</div>

        <div className={sty.pen}></div>
      </div>

      <div className={sty.s}>create an account so you can manage your money faster</div>

      <Form method="post">
        <Input2 type="text" placeholder="First Name" name="first_name" />
        <Input2 type="text" placeholder="Last Name" name="last_name" />
        <Input2 type="text" placeholder="Username" name="username" />
        <Input2 type="text" placeholder="Email" name="email" />
        {/*
        <Input2 type="text" placeholder="First Name" name="first_name" />*/}

        <Input2 name="password" type="password" placeholder="Password" hasIcon="show" toggleIconWith="hide" />
        <Input2 type="password" placeholder="Confirm Password" hasIcon="show" toggleIconWith="hide" />

        <div className={sty.remeber}>
          <div className={sty.l}>
            <input type="checkbox" />
            <div className={sty.text}>I&apos;m at least 18 years old and agree to the following terms:</div>
          </div>
          {/*<div className={sty.r}>
		<a href="">Forgot Password?</a>
		</div>*/}
        </div>

        <div className={sty.note}>
          By tapping Next. I've read and agree to the &nbsp;
          <a className={sty.imp} href="/b/sign.pdf">
            E-Sign Disclosure and Consent
          </a>{' '}
          to receive all communications electronically
        </div>

        <SubmitBtn2 label="Continue" />
      </Form>

      <Footer2 />
    </div>
  );
}

import Input2, { Input3, CountryOptions } from '../../../components/input2';
import SubmitBtn2 from '../../../components/submit2';
import sty from './s.module.scss';
import Header2 from '../../../components/header2';
import Header3 from '../../../components/header3';
import Footer2 from '../../../components/footer2';
//import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import Notif from '../../../components/notif';
import Select2 from '../../../components/select2';
import { loaderPostFetch } from '../util';
import { useEffect, useState } from 'react';
import UploadFile, { UploadFile2 } from '../../../components/uploadfile';
import { logout } from '../../../components/dropdown';

import { Outlet, Link, useLoaderData, useActionData, Form } from 'react-router-dom';

export async function vaaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();
  formData.append('submit_verification', 'submit_verification');

  /*var blob = new Blob([JSON.stringify([0, 1, 2])], { type: 'image/jpeg' });
  var fileOfBlob = new File([blob], 'empty.jpeg');

  formData.append('idcardFront', fileOfBlob, 'empty.jpeg');
  //formData.append('pic', fileOfBlob, 'empty.jpeg');*/

  let tok = window.localStorage.getItem('tempbaUid');
  if (tok != null) {
    formData.append('token', tok);
  }

  /*const updates = Object.fromEntries(formData);
  updates['action'] = 'code';
  let contact;
  //const contact = await
  loaderPostFetch(`api/users.php`, updates)
    .then((s) => {
      if (s?.error) {
        //s?.message
      } else {
      }
    })
    .catch((e) => {
      console.log(e);
    });

  return { contact };*/

  const contact = await loaderPostFetch(`api/users.php`, formData);

  return contact;
}

export async function valoader({ params }) {
  const formData = new FormData();
  formData.append('getdocs', 'getdocs');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  //return { ...params };
}

export default function VerifyAccount() {
  const { email, docs } = useLoaderData();

  let actionData = useActionData();
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.icon == 'success') {
        //window.localStorage.setItem('baUid', s?.jwt);
        window.localStorage.removeItem('tempbaUid');

        sN({ display: true, type: 'success', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'success', msg: `` });
          //window.location.href = '../dashboard';
          window.location.href = `../account/under-review`;
        }, 5000);
      } else if (s?.icon == 'error') {
        sN({ display: true, type: 'error', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      }
      /*if (s?.error) {
        //s?.message

        sN({ display: true, type: 'error', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      } else {
        window.localStorage.setItem('baUid', s?.jwt);
        //window.location.reload();
        sN({ display: true, type: 'success', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'success', msg: `` });
          window.location.href = '../../dashboard';
        }, 5000);
      }*/
    }
  }, [actionData]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />
      <div className={sty.bg}>
        <Header3
          title="Logout"
          onClick={() => {
            //window.location.href = `https://${window.location.hostname}/wp-user/auth/`;
            logout();
            window.location.href = `https://${window.location.hostname}/wp-user/auth/`;
          }}
        />

        <div className={sty.sub}>
          <div className={sty.text}>Submit Verification</div>

          <div className={sty.lock}></div>
        </div>

        <div className={sty.s}>
          To request an account verification, kindly provide your information with a valid means of Identification
          attached as an image document.
        </div>

        <Form method="post" encType="multipart/form-data">
          <div className={sty.h2}>Personal Info</div>
          <div className={sty.h3}>Please fill in your details correctly </div>
          <Select2 icon="globe" label=" " name="country">
            <option>Select country</option>
            <CountryOptions />
          </Select2>

          <Input3 type="text" name="phone" placeholder="Enter phone number" hasIcon="phone" />
          <Input3 type="text" name="home_address" placeholder="Address" hasIcon="address" />

          <div className={sty.h2}>Identity Proof</div>
          <div className={sty.h3}>select document type below</div>

          <Select2 icon="id" label=" " name="type">
            <option>Driver’s License</option>
            {docs &&
              docs?.map((v, o8) => {
                return <option key={o8}>{v?.doc_name}</option>;
              })}
          </Select2>

          <UploadFile2 bg="#fff" label=" " name="pic" />
          <UploadFile bg="#fff" label=" " name="idcardFront" />

          <SubmitBtn2 label="Continue" />
        </Form>

        {/*<div className={sty.resend}>
        Haven’t gotten the mail yet? <a>Resend</a>
      </div>*/}

        <Footer2 />
      </div>
    </>
  );
}

import s from './sun.module.scss';

export default function Row2({ h, sk, wasread = 'no' }) {
  return (
    <div className={s.b}>
      <div className={s.h}>
        <div>{h}</div>
        {wasread == 'no' ? (
          <div className={s.mar}>
            <div className={s.icon}></div>
            <input type="submit" className={s.text} value="Mark as read" />
          </div>
        ) : null}
      </div>
      <div className={s.s2} dangerouslySetInnerHTML={{__html:sk}} />
    </div>
  );
}

import { Link, useLoaderData, Form } from 'react-router-dom';

import { useRef, useState, useEffect } from 'react';
import af from './style.module.scss';
import Row2 from '../../../components/row2';
import { loaderPostFetch } from '../util';
import { useActionData, useSubmit } from 'react-router-dom';
import Notif from '../../../components/notif';

export const afloader = () => {
  /*
   */
};

export async function naction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();

  formData.append('mnotificationar', 'mnotificationar');
  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const contact = await loaderPostFetch(`api/users.php`, formData);

  return contact;
}

export async function nloader({ params }) {
  const formData = new FormData();
  formData.append('getnotifications', 'getnotifications');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const res = await loaderPostFetch(`api/users.php`, formData);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...res };
}

export default function NotificationsIndex() {
  const { notifications } = useLoaderData();
  /*const y = [
    {
      name: 'Credit ',
      sub: 'To deposit, choose the payment method panel and make the payment to the displayed address. After payment has been made, come back to fill this form.',
    },
    {
      name: 'Credit ',
      sub: 'To deposit, choose the payment method panel and make the payment to the displayed address. After payment has been made, come back to fill this form.',
    },
    {
      name: 'Credit ',
      sub: 'To deposit, choose the payment method panel and make the payment to the displayed address. After payment has been made, come back to fill this form.',
    },
    {
      name: 'Credit ',
      sub: 'To deposit, choose the payment method panel and make the payment to the displayed address. After payment has been made, come back to fill this form.',
    },
  ];*/

  let actionData = useActionData();

  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.status) {
        if (s?.status == 'success') {
          sN({ ...n, display: true, type: 'success', msg: 'Marked as read' });
        } else {
          sN({ ...n, display: true, type: 'error', msg: 'Something went wrong!' });
        }
      }
      /*sN({ display: true, type: 'success', msg: `${s?.message1}` });
       */
      setTimeout(() => {
        sN({ display: false, type: 'success', msg: `` });
      }, 5000);
    }
  }, [actionData]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <div>
        <div className={af.body}>
          <div className={af.h0}>All notifications</div>

          {!notifications || notifications?.length == 0 ? (
            <div className={af.empty}>
              <div className={af.c}>
                <div className={af.h}>No notifications History</div>
                <div className={af.s}>Your recent notifications should appear here </div>
              </div>
            </div>
          ) : (
            <>
              {notifications &&
                notifications?.map((n, i) => {
                  return (
                    <Form method="post" key={i}>
                      <input type="hidden" name="id" value={n?.id} />
                      <Row2 h={n?.title} sk={n?.body} wasread={n?.wasread} />
                    </Form>
                  );
                })}
            </>
          )}
          {/*y?.map((n, i) => {
          return <Row2 key={i} h={n?.name} sk={n?.sub} />;
        })*/}
        </div>
      </div>
    </>
  );
}

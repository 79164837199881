import ci from './ci.module.scss';
import { useRef, useEffect } from 'react';

export default function CopyInput({ label, value = '', proceed }) {
  const y = useRef();
  useEffect(() => {
    if (value) {
      y.current.value = value;
    }
  }, [value]);
  async function copySomething(text) {
    try {
      const toCopy = text || window.location.href;
      await navigator.clipboard.writeText(toCopy);
      console.log('Text or Page URL copied');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }
  const co = () => {
    console.log('copied');
    copySomething(value);
    if (proceed != null) {
      proceed();
    }
  };
  return (
    <>
      <label className={ci.label}>{label}</label>
      <div className={ci.c}>
        <input ref={y} type="text" />
        <div
          className={ci.e}
          onClick={() => {
            co();
          }}
        >
          <div className={ci.icon}></div>
          <div className={ci.tx}> Copy</div>
        </div>
      </div>
    </>
  );
}

import Card from '../../../components/card';
import CopyInput from '../../../components/copyinput';
import Select1 from '../../../components/select';
import UploadFile from '../../../components/uploadfile';

import SubmitBtn from '../../../components/submit';

import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import up from './style.module.scss';

/*
export async function ssaction({ request, params }) {

//loader will fetch details
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  const contact = await loaderPostFetch(`${apiUrl}/newsession`, updates);
  return { contact };
}

export async function ssloader() {

  const sessions = await getfetch(`${apiUrl}/sessionlist`, {}); 
  return { sessions };
}*/

export default function KycIndex() {
  return (
    <>
      <div className={up?.h}>KYC</div>
      <div className={up.t}>Submit verification</div>

      <Form method="post">
        <div className={up.s2}>
          To request an account verification, kindly provide your information with a valid means of Identification
          attached as an image document.
        </div>

        <CopyInput label="Address:" value="1BfUFLugcHnwrsixFdkErc6f36oYDxM4NR" />

        <Select1 label="Document Type" name="type">
          <option>Driver’s License</option>
        </Select1>

        <UploadFile bg="#fff" label="payment proof" name="file" />

        <SubmitBtn label="Request verification " />
      </Form>
    </>
  );
}

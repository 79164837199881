import Input2 from '../../../components/input2';
import SubmitBtn2 from '../../../components/submit2';
import sty from './s.module.scss';
import Header2 from '../../../components/header2';
import Footer2 from '../../../components/footer2';
//import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';
import Notif from '../../../components/notif';

import { loaderPostFetch } from '../util';
import { useEffect, useState } from 'react';

import { Outlet, Link, useLoaderData, useActionData, Form } from 'react-router-dom';

export async function veaction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();
  const updates = Object.fromEntries(formData);
  updates['action'] = 'code';

  const contact = await loaderPostFetch(`api/users.php?crud=verifyuser`, formData);

  return contact;
}
export async function veloader({ params }) {
  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params };
}

export default function VerifyEmail() {
  const { email } = useLoaderData();

  let actionData = useActionData();
  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      if (s?.error) {
        //s?.message

        sN({ display: true, type: 'error', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      } else {
        if (Object.keys(s).length != 1) {
          sN({ display: true, type: 'success', msg: `${s?.message}` });
          setTimeout(() => {
            sN({ display: false, type: 'success', msg: `` });

            if (s?.verification_status == 'Not Verified') {
              window.localStorage.setItem('account_verification', false);
              window.localStorage.setItem('tempbaUid', s?.jwt);

              window.location.href = `../account/verification`;
            } else if (s?.verification_status == 'Pending Verification') {
              window.localStorage.setItem('account_verification', false);
              window.localStorage.setItem('tempbaUid', s?.jwt);

              window.location.href = `../account/under-review`;
            } else {
              window.localStorage.setItem('baUid', s?.jwt);
              //window.location.reload();
              sN({ display: true, type: 'success', msg: `${s?.message}` });
              setTimeout(() => {
                sN({ display: false, type: 'success', msg: `` });
                window.location.href = '../dashboard';
              }, 5000);
            }
          }, 5000);
        } else {
          sN({ display: true, type: 'error', msg: `Something went wrong` });
          setTimeout(() => {
            sN({ display: false, type: 'error', msg: `` });
          }, 5000);
        }
        /*
        window.localStorage.setItem('baUid', s?.jwt);
        //window.location.reload();
        sN({ display: true, type: 'success', msg: `${s?.message}` });
        setTimeout(() => {
          sN({ display: false, type: 'success', msg: `` });
          window.location.href = '../../dashboard';
        }, 5000);*/
      }
    }
  }, [actionData]);
  const resendCode = () => {
    //fetch()
    let formData = new FormData();
    if (email) {
      formData.append('email', email);
    }
    formData.append('resendconfirmcode', 'resendconfirmcode');

    sN({ display: true, type: 'success0', msg: `Please Wait` });

    loaderPostFetch(`api/users.php`, formData)
      .then((d) => {
        sN({ display: true, type: 'success', msg: `Code Sent` });
        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      })
      .catch((e) => {
        sN({ display: true, type: 'error', msg: `Something went wrong` });

        setTimeout(() => {
          sN({ display: false, type: 'error', msg: `` });
        }, 5000);
      });
  };

  return (
    <div>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      <Header2 href="../login" title="Sign In" />

      <div className={sty.sub}>
        <div className={sty.text}>Email Verification</div>

        <div className={sty.lock}></div>
      </div>

      <div className={sty.s}>
        we have sent code your mail:
        <br />
        {email}
      </div>
      <Form method="post">
        <input type="hidden" name="email" value={email} />
        <Input2 type="text" name="ver_code" placeholder="Enter Code" />

        {/*<div className={sty.remeber}>
		<div className={sty.l}>
		<input type="checkbox" />
		<div className={sty.text}>I&apos;m at least 18 years old and agree to the following terms:</div>
		</div>
		<div className={sty.r}>
		<a href="">Forgot Password?</a>
		</div>
		</div>

		<div className={sty.note}>
By tapping Next. I've read and agree to the  
		<div className={sty.imp}>E-Sign Disclosure and Consent 
		</div>to receive all communications  electronically

		</div>*/}

        <SubmitBtn2 label="Confirm Code" />
      </Form>

      <div
        className={sty.resend}
        onClick={() => {
          resendCode();
        }}
      >
        Haven’t gotten the mail yet? <a>Resend</a>
      </div>

      <Footer2 />
    </div>
  );
}

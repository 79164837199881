import s from './style.module.scss';

import Input1, { Input10 } from '../../../components/input';
import { useRef, useState, useEffect } from 'react';
import SubmitBtn from '../../../components/submit';
import SendNotice from '../../../components/sendnotice';
import { loaderPostFetch } from '../util';

import TrsRow from '../../../components/trs';
import { useActionData, useSubmit, useNavigate } from 'react-router-dom';

import Card, { Card2 } from '../../../components/card';
import TextAr from '../../../components/textarea';

import Notif from '../../../components/notif';
import { Outlet, Link, useLoaderData, Form } from 'react-router-dom';

export async function saction({ request, params }) {
  //loader will fetch details
  const formData = await request.formData();

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const updates = Object.fromEntries(formData);

  //const contact = await loaderPostFetch(`api/users.php`, formData);

  return updates;
}

export async function sloader({ params }) {
  const formData = new FormData();
  formData.append('gettrslist', 'gettrslist');

  let tok = window.localStorage.getItem('baUid');
  if (tok != null) {
    formData.append('token', tok);
  }
  const perc = await loaderPostFetch(`api/users.php`, formData);

  const formData2 = new FormData();
  formData2.append('getperc', 'getperc');
  if (tok != null) {
    formData2.append('token', tok);
  }
  const perc2 = await loaderPostFetch(`api/users.php`, formData2);

  //const sessions = await getfetch(`${apiUrl}/sessionlist`, {});
  return { ...params, ...perc, ...perc2 };
}
export default function SendIndex() {
  let actionData = useActionData();
  const { transactions, currency, tb, trx_notif } = useLoaderData();
  //const { perc } = useLoaderData();
  const navigate = useNavigate();

  const [n, sN] = useState({ display: false, type: 'success', msg: '' });

  useEffect(() => {
    /*console.log({ actionData });*/
    if (actionData) {
      let s = actionData;
      console.log(s);
      navigate('../send/confirm', { state: { ...s } });
      /*if (s?.icon) {
        if (s?.icon) {
          sN({ ...n, type: 'success', msg: 'Successful! Await Confirmation' });
        } else {
          sN({ ...n, type: 'error', msg: 'Something went wrong!' });
        }
      }*/
      /*
      setTimeout(() => {
        sN({ display: false, type: 'success', msg: `` });
      }, 5000);
      */
    }
  }, [actionData]);

  return (
    <>
      <Notif display={n?.display} type={n?.type} msg={n?.msg} />

      {/*trx_notif?.length!=0?<SendNotice display={true} t={trx_notif?.[0]?.title} b={trx_notif?.[0]?.body} />:null*/}

      <div>
        <div className={s.h} style={{ marginBottom: '1rem' }}>
          Send{' '}
        </div>

        <Form method="post">
          <input type="hidden" name="newtrs" value="newtrs" />

          <Card2>
            <Input1 name="bank" placeholder="Enter Bank " label="Bank*" />

            <div className={s.duo}>
              <Input1 name="swift" placeholder="Enter Swift Code " label="Swift Code *" />

              <Input1 name="currency" placeholder="Enter Currency" label="Currency *" />
            </div>

            <div className={s.duo}>
              <Input1 name="routing" placeholder="Enter Routing Number" label="Routing Number *" />

              <Input1 name="country" placeholder="Enter Country " label="Country *" />
            </div>

            <Input1 name="act_no" placeholder="Enter Account Number " label="Account Number *" />

            <Input1 name="act_hold_name" placeholder="Enter Account Holder Name " label="Account Holder Name *" />

            <Input10
              name="amount"
              placeholder="Enter Amount "
              potentialValue={tb?.current_balance}
              format={false}
              label="Amount *"
            />
            {/*<Input10

              name="amount"
              placeholder="Enter Amount "
              potentialValue={tb?.current_balance}
              format={false}
              label="Amount *"
            />
            <Input1
              name="amount"
              placeholder="Enter Amount "  
              label="Amount *"
            />*/}

            <TextAr name="note" label="Note *" placeholder="Enter Note " />
            {/*trx_notif?.length==0?
            <SubmitBtn label="Submit Transfer " />:null*/}
            <SubmitBtn label="Submit Transfer " />
          </Card2>
        </Form>
      </div>

      {transactions?.length > 0 ? (
        <>
          {/*<p> Transactions</p> */}

          {transactions?.map((no, i) => {
            return (
              <TrsRow
                key={i}
                currency={currency}
                type={no?.type}
                amount={no?.amount}
                status={no?.status}
                date={no?.created_at}
              />
            );
          })}
        </>
      ) : null}
    </>
  );
}
